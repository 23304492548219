<template>
    <div class="height">
        <div class="web" >
            <div class="new-bg" :class="`fen${page} ${page==module.data.length+8?'last':''}`" v-if="info">
                <vue-header title="健康报告" :report="true" :fixed="true" :notoken="noToken" :isLoginFun="isLoginFun"/>
                <div class="erect">
                    <div class="new-box y1">
                        <div class="intro-user">
                            <div class="flex flex-x">
                                <div class="remind">郑重提醒：本报告不作医学诊断及购药依据之用！</div>
                            </div>
                            <div class="intro-portrait">
                                <img :src="user.url" v-if="user.url" />
                                <img src="../../../../images/user.png" v-else-if="info.sex==1" />
                                <img src="../../../../images/girl.png" v-else />
                            </div>
                            <div class="newre-name">{{user.name?user.name:(user.nikeName?user.nikeName:'')}}</div>
                            <div class="newre-info">
                                <div class="use-mess flex flex-x flex-y">
                                    <p class="flex-1">性别：{{info.sex==1?'男':'女'}}</p>
                                    <p class="flex-1">年龄：{{info.age}}岁</p>
                                    <p class="flex-1">身高：{{info.height}}cm</p>
                                    <p class="flex-1">体重：{{info.weight}}kg</p>
                                </div>
                            </div>
                            <div v-if="info.report_section.indexOf('1')!==-1">
                                <div class="flex flex-x">
                                    <div class="body-h1">您的评估结果</div>
                                </div>
                                <div class="physical" >
                                    <div class="flex flex-x">
                                        <div v-for="( item, index ) in info.sick_text_array" v-bind:key="index" class="physical-sick">
                                            <img src="../../../../images/q1.png" v-if="item=='平衡'"/>
                                            <img src="../../../../images/q2.png" v-if="item=='肾虚'"/>
                                            <img src="../../../../images/q3.png" v-if="item=='气虚'"/>
                                            <img src="../../../../images/q4.png" v-if="item=='痰湿'"/>
                                            <img src="../../../../images/q5.png" v-if="item=='脾虚'"/>
                                            <img src="../../../../images/q6.png" v-if="item=='郁滞'"/>
                                            <img src="../../../../images/q7.png" v-if="item=='阳虚'"/>
                                            <img src="../../../../images/q8.png" v-if="item=='阴虚'"/>
                                            <p>{{item}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="info.report_section.indexOf('3')!==-1">
                            <div class="flex flex-x">
                                <div class="body-h1">元气平衡度</div>
                            </div>
                            <p class="introduce" @click="alertFun({title:'什么是元气平衡度',text:'元气平衡度指身体的平衡状态，中医认为，“平衡”才健康，平衡包括阴阳平衡、气血平衡、脏腑平衡、精神平衡等各方面。元气平衡度越平衡，身体越平衡，身体各机能状态越好，即使生病也容易恢复。'})"><van-icon name="question-o" />什么是元气平衡度</p>
                            <div class="body-mark flex">
                                <div class="flex-1">
                                    <div id="main" class="balance-meter"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="new-box new-tips y2" v-if="info.report_section.indexOf('2')!==-1">
                        <div class="flex flex-x">
                            <div class="body-h1">中医体检结果</div>
                        </div>
                        <p class="introduce" @click="alertFun({title:'什么是中医体检结果',text:'中医诊断讲究“辨证论治“，证是中医学特有的诊断概念，是对疾病或身体状态当前阶段的病位、病性等所做的概括，是对疾病或身体状态当前本质所作的结论。证候不同于西医的疾病，更能反映疾病的本质 。证候会随着疾病的发展阶段和身体的健康状态变化而变化。'})"><van-icon name="question-o" />什么是中医体检结果</p>
                        <div class="body-result flex" v-for="( item, index ) in info.sick_text_array" v-bind:key="index">
                            <div class="body-dialog flex-1">
                                <div>
                                    <div class="dialog-title">{{item}}</div>
                                    <div v-html="JSON.parse(info.viscera.syndrome_desc)[item]"></div>
                                </div>
                            </div>
                            <img src="../../../../images/robot.png" class="robot"/>
                        </div>
                        <div class="body-result flex" v-if="info.age>18 || info.sick_text_array.length==1&&(info.sick_text_array[0]=='平衡'||info.sick_text_array[0]=='基本平衡'||info.sick_text_array[0]=='健康')">
                            <div class="body-dialog flex-1">
                                <div class="dialog-title">常见不适症状：</div>
                                <div>{{info.viscera.syndrome_show}}</div>
                            </div>
                            <img src="../../../../images/robot.png" class="robot"/>
                        </div>
                        
                    </div>
                    <div class="new-box new-tips y3" v-if="info.report_section.indexOf('9')!==-1">
                        <div class="flex flex-x">
                            <div class="body-h1">常见症状</div>
                        </div>
                        <p class="introduce"></p>
                        <div class="body-result flex" >
                            <div class="body-dialog flex-1">
                                <div>
                                    <div>{{info.viscera.zhengzhuang}}</div>
                                </div>
                            </div>
                            <img src="../../../../images/robot.png" class="robot"/>
                        </div>
                    </div>
                    <div class="new-box new-tips y3" v-if="info.report_section.indexOf('10')!==-1">
                        <div class="flex flex-x">
                            <div class="body-h1">重点预防疾病</div>
                        </div>
                        <p class="introduce"></p>
                        <div class="body-result flex" >
                            <div class="body-dialog flex-1">
                                <div>
                                    <div>{{info.viscera.yufangjibing}}</div>
                                </div>
                            </div>
                            <img src="../../../../images/robot.png" class="robot"/>
                        </div>
                    </div>
                    <div class="new-box new-tips y4" v-if="info.report_section.indexOf('5')!==-1">
                        <div class="flex flex-x">
                            <div class="body-h1">体重指数(BMI)</div>
                        </div>
                        <p class="introduce" @click="alertFun({title:'什么是BMI',text:'BMI也称为身体质量指数（英文为Body Mass Index，简称BMI），是用体重公斤数除以身高米数平方得出的数字，是国际上常用的衡量人体胖瘦程度以及是否健康的一个标准。'})"><van-icon name="question-o" />BMI</p>
                        <div class="exponent flex">
                            <div class="duce_bmi_box flex-1">
                                <div class="duce">您的指数</div>
                                <div class="duce_bmi">{{ info.bmi }}</div>
                                <span class="weight-fat blue" v-if="MBItext(info.bmi)=='偏瘦'">{{ MBItext(info.bmi) }}</span>
                                <span class="weight-fat green" v-if="MBItext(info.bmi)=='正常'">{{ MBItext(info.bmi) }}</span>
                                <span class="weight-fat" v-if="MBItext(info.bmi)=='偏胖'">{{ MBItext(info.bmi) }}</span>
                                <span class="weight-fat orange" v-if="MBItext(info.bmi)=='肥胖'">{{ MBItext(info.bmi) }}</span>
                                <span class="weight-fat red" v-if="MBItext(info.bmi)=='重度肥胖'">{{ MBItext(info.bmi) }}</span>
                            </div>
                            <div class="flex-1">
                                <div class="duce">您的适合体重</div>
                                <div class="duce_bmi">{{ info.suit_weight }}<span>kg</span></div>
                            </div>
                        </div>
                        <div class="expons flex flex-v">
                            <div class="expon">
                                偏瘦:&lt;18.5
                            </div>
                            <div class="expon">
                                正常:18.5～23.9
                            </div>
                            <div class="expon">
                                偏胖:24～26.9
                            </div>
                            <div class="expon">
                                肥胖:27～29.9
                            </div>
                            <div class="expon">
                                重度肥胖:≥30
                            </div>
                        </div>
                    </div>
                    <div class="new-box new-tips lv-box y5" v-if="info.report_section.indexOf('6')!==-1">
                        <div class="flex flex-x">
                            <div class="body-h1">身体七大系统评估</div>
                        </div>
                        <div class="assessment">
                            <div class="table-mark">
                                <table class="newre-table">
                                    <thead>
                                    <tr>
                                        <th>系统名称</th>
                                        <th>指数（5～1）</th>
                                    </tr>  
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item , index) in info.system" :key="index">
                                        <td>{{index}}</td>
                                        <td>{{item}}</td>
                                    </tr>
                                    </tbody>  
                                </table>
                            </div>
                            <div class="sick-dosc">
                                <div>
                                    <p>"5"表示处于最佳状态</p>
                                    <p>"4"表示处于健康状态</p>
                                    <p>"3"表示处于轻度亚健康状态</p>
                                    <p>"2"表示处于重度亚健康状态</p>
                                    <p>"1"表示处于异常状态</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="new-box new-tips lv-box y6" v-if="info.report_section.indexOf('4')!==-1">
                        <div class="flex flex-x">
                            <div class="body-h1">我有哪些健康风险？</div>
                        </div>
                        <div class="body-gpl body-result flex" v-if="info.age>18 && !(info.sick_text_array.length==1&&(info.sick_text_array[0]=='平衡'||info.sick_text_array[0]=='基本平衡'||info.sick_text_array[0]=='健康'))">
                            <div class="body-dialog body-white flex-1">
                                <div class="dialog-title">需要预防的重大疾病：</div>
                                <div v-html="info.big.join('、')"></div>
                            </div>
                            <img src="../../../../images/robot.png" class="robot"/>
                        </div>
                        <div class="body-gpl body-result flex">
                            <div class="body-dialog body-white flex-1">
                                <div class="dialog-title">您需要关注的脏腑器官：</div>
                                <div v-html="info.viscera.viscera"></div>
                            </div>
                            <img src="../../../../images/robot.png" class="robot"/>
                        </div>
                        <div class="body-result flex" >
                            <div class="body-dialog body-white flex-1">
                                <div class="dialog-title">您的健康风险：</div>
                                <div v-if="info.age>18 && !(info.sick_text_array.length==1&&(info.sick_text_array[0]=='平衡'||info.sick_text_array[0]=='基本平衡'||info.sick_text_array[0]=='健康'))">根据您的性别年龄和上述各项健康指标，结合临床大数据研究显示，相对于同年龄段的人群您患<span>{{info.big.join('、')}}</span>的概率更高。大部分疾病的发生和传变都是一个漫长的过程，根本原因在于身体健康状态的失衡。如果诸多轻症长久不调理或潜藏未觉，就会增加向重疾恶化的风险</div>
                                <div v-else>良好。</div>
                            </div>
                            <img src="../../../../images/robot.png" class="robot"/>
                        </div>
                    </div>
                    <div class="new-box new-tips y7" :class="{noInfo:!info.index_list}" v-if="info.report_section.indexOf('7')!==-1 && info.index_list">
                        <div>
                            <!-- <div class="flex flex-x">
                                <div class="body-h1">元气指数趋势图</div>
                            </div>
                            <div class="trend7">显示最近7天数据趋势</div>
                            <div v-if="info.index_list.score">
                                <div class="trend-alert">指数越高，身体健康越平衡</div>
                                <div class="balance-meter broken" id="broken"></div>
                            </div> -->
                            <div v-if="info.index_list.bmi" class="healthy-bmi">
                                <div class="healthy-1 flex">
                                    <div class="healthy">体重指数(BMI)</div>
                                </div>
                                <div class="trend-alert">体重指数（BMI）= 体重/身高 ^2</div>
                                <div class="balance-meter broken" id="weight"></div>
                            </div>
                        </div>
                    </div>
                    <div class="new-box new-tips y8" v-if="info.women && info.report_section.indexOf('8')!==-1">
                        <div>
                            <div class="flex flex-x">
                                <div class="body-h1">女性产后保健</div>
                            </div>
                            <p class="introduce">仅针对产后女性</p>
                            <div class="assessment assessment-no">
                                <div class="sick-doscs">
                                    <div class="sick-color">
                                        <div class="flex">
                                            <div class="sick-title flex flex-y flex-x">
                                                <div>
                                                    <div>健</div>
                                                    <div>康</div>
                                                    <div>状</div>
                                                    <div>态</div>
                                                    <div>评</div>
                                                    <div>估</div>
                                                </div>
                                            </div>
                                            <div class="sick-text flex-1 flex flex-y">
                                                <div>
                                                    <div>{{info.women.first_part}}</div>
                                                    <div class="second">{{info.women.second_part}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sick-color">
                                        <div class="flex">
                                            <div class="sick-title flex flex-y flex-x">
                                                <div>
                                                    <div>产</div>
                                                    <div>褥</div>
                                                    <div>期</div>
                                                    <div>易</div>
                                                    <div>得</div>
                                                    <div>病</div>
                                                    <div>症</div>
                                                </div>
                                            </div>
                                            <div class="sick-text flex-1 flex flex-y">
                                                <div>根据您现在的中医健康状态分析，产褥期您需注意以下病症：{{info.women.stegmonth_disease}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sick-color">
                                        <div class="flex">
                                            <div class="sick-title flex flex-y flex-x">
                                                <div>
                                                    <div>产</div>
                                                    <div>后</div>
                                                    <div>恢</div>
                                                    <div>复</div>
                                                    <div>后</div>
                                                    <div>期</div>
                                                    <div>易</div>
                                                    <div>得</div>
                                                    <div>病</div>
                                                    <div>症</div>
                                                </div>
                                            </div>
                                            <div class="sick-text flex-1 flex flex-y">
                                                <div>根据您现在的中医健康状态分析，产后恢复期您需注意以下病症：{{info.women.stegmonth_disease}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 药茶养生、艾灸养生、经络养生、药膳养生、六茶养生、经方、音乐养生、起居指导 -->
                    <div class="new-box new-tips" :class="`y${index+9} ${index%2===1?'bai-box':'lv-box'}`" v-for="(items, index) in module.data" :key="index">
                        <div class="body-result flex" v-if="index==0">
                            <div class="body-dialog body-white flex-1">
                                <div class="dialog-title">以下是根据您的情况为您个性化定制的养生方案。</div>
                            </div>
                            <img src="../../../../images/robot.png" class="robot"/>
                        </div>
                        <div class="flex flex-x">
                            <div class="body-h1">{{items.module_name}}</div>
                        </div>
                        <div class="assessment assessment-no">
                            <div class="sick-list flex flex-x">
                                <div class="new-sick green-sick" v-if="info.sick_text_array.length==1&&(info.sick_text_array[0]=='平衡'||info.sick_text_array[0]=='基本平衡'||info.sick_text_array[0]=='健康')">平衡</div>
                                <div v-else :class="{active:item1.active}"  v-for="( item1, index1 ) in items.description_list" v-bind:key="index1" class="new-sick new-easy" @click="()=>change({
                                    index:index,
                                    index1:index1
                                })">
                                    {{item1.syndrome_name}}
                                </div>
                            </div>
                            <div class="sick-list flex">
                                <div v-for="( item2, index2 ) in items.description_list" v-bind:key="index2">
                                    <div v-if="item2.list && item2.active" class="flex flex-v">
                                        <div v-for="( item3, index3 ) in item2.list" v-bind:key="index3">
                                            <div class="new-sick new-green " :class="{active:item3.active}" v-if="item3.module_value[0].value" @click="()=>changeTwo({
                                                index:index,
                                                index1:index2,
                                                index2:index3
                                            })">{{item3.module_value[0].value}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-for="( item2, index3 ) in items.description_list" v-bind:key="index3">
                                <div v-if="item2.list && item2.active">
                                    <div  v-for="( item3, index3 ) in item2.list" v-bind:key="index3">
                                        <div v-if="item3.active">
                                            <div v-if="items.is_need_audio==1" class="sick-audio">
                                                <div v-for="( audio, index6 ) in item3.audio" v-bind:key="index6">
                                                    <audio :src="audio" controls="controls">
                                                        您的浏览器不支持html5的audio标签
                                                    </audio>
                                                </div>
                                            </div>
                                            <div class="sick-doscs">
                                                <div v-for="( item4, index4 ) in item3.module_value" v-bind:key="index4" class="sick-color">
                                                    <div v-if="index4>0" class="flex">
                                                        <div class="sick-title flex flex-y flex-x">
                                                            <div>
                                                                <div v-for="(title,title1) in item4.title" v-bind:key="title1">{{title}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="sick-text flex-1 flex flex-y" v-html="item4.value"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="items.is_need_image==1" class="sick-img">
                                                <div v-for="( img, index5 ) in item3.images" v-bind:key="index5">
                                                    <img :src="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="items.is_show_goods==1">
                                        <div v-for="( item3, index3 ) in item2.list" v-bind:key="index3">
                                            <div v-if="item3.active">
                                                <div class="syndrome-shop flex" v-if="item3.goodsURL" @click="()=>href(item3.goodsURL)">
                                                    <div class="syndrome-tea flex flex-1" >
                                                        <div class="syndrome-img" v-if="item3.goodsImg">
                                                            <img :src="item3.goodsImg"/>
                                                        </div>
                                                        <div class="tea-box flex-1" v-if="item3.goods_name && item3.goods_price">
                                                            <p>{{item3.goods_name}}</p>
                                                            <i><span>¥</span>{{item3.goods_price}}</i>
                                                        </div>
                                                    </div>
                                                    <div class="syndrome-look">前往查看</div>
                                                </div>
                                                <div v-if="items.is_show_goodthing==1 && item3.goods_more" class="syndrome-mose" @click="()=>href(item3.goods_more)">
                                                    更多好物
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="fen-page">
                        <div class="s-page">
                            <div class="prev" @click="()=>pageFun({
                                page:page-1,
                                add:-1
                            })">
                                <div>上</div>
                                <div>一</div>
                                <div>页</div>
                            </div>
                        </div>
                        <div class="s-page">
                            <div class="next" @click="()=>pageFun({
                                page:page+1,
                                add:1
                            })">
                                <div>下</div>
                                <div>一</div>
                                <div>页</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="horizontal">
                    <div class="hori-box y1">
                        <div class="flex">
                            <div>
                                <div class="user-infox flex flex-y">
                                    <div class="user-portrait">
                                        <img :src="user.url" v-if="user.url" />
                                        <img src="../../../../images/user.png" v-else-if="info.sex==1" />
                                        <img src="../../../../images/girl.png" v-else />
                                    </div>
                                    <div class="user-jeep">{{user.name?user.name:(user.nikeName?user.nikeName:'')}}</div>
                                    <div class="user-jeep">性别：{{info.sex==1?'男':'女'}}</div>
                                    <div class="user-jeep">年龄：{{info.age}}岁</div>
                                    <div class="user-jeep">身高：{{info.height}}cm</div>
                                    <div class="user-jeep">体重：{{info.weight}}kg</div>
                                </div>
                            </div>
                            <div class="flex-1">
                                <div class="remind">郑重提醒：本报告不作医学诊断及购药依据之用！</div>
                            </div>
                        </div>
                        <div>
                            <div class="flex flex-x">
                                <div class="body-h1">您的评估结果</div>
                            </div>
                            <div class="flex flex-x">
                                <div class="physical witth">
                                    <div class="flex flex-x">
                                        <div v-for="( item, index ) in info.sick_text_array" v-bind:key="index" class="physical-sick">
                                            <img src="../../../../images/q1.png" v-if="item=='平衡'"/>
                                            <img src="../../../../images/q2.png" v-if="item=='肾虚'"/>
                                            <img src="../../../../images/q3.png" v-if="item=='气虚'"/>
                                            <img src="../../../../images/q4.png" v-if="item=='痰湿'"/>
                                            <img src="../../../../images/q5.png" v-if="item=='脾虚'"/>
                                            <img src="../../../../images/q6.png" v-if="item=='郁滞'"/>
                                            <img src="../../../../images/q7.png" v-if="item=='阳虚'"/>
                                            <img src="../../../../images/q8.png" v-if="item=='阴虚'"/>
                                            <p>{{item}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="flex flex-x">
                                <div class="body-h1">元气平衡度</div>
                            </div>
                            <p class="introduce" @click="alertFun({title:'什么是元气平衡度',text:'元气平衡度指身体的平衡状态，中医认为，“平衡”才健康，平衡包括阴阳平衡、气血平衡、脏腑平衡、精神平衡等各方面。元气平衡度越平衡，身体越平衡，身体各机能状态越好，即使生病也容易恢复。'})"><van-icon name="question-o" />什么是元气平衡度</p>
                            <div class="body-mark flex">
                                <div class="flex-1">
                                    <div id="y-main" class="balance-meter"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hori-box y2">
                        <div class="flex flex-x">
                            <div class="body-h1">中医体检结果</div>
                        </div>
                        <p class="introduce" @click="alertFun({title:'什么是中医体检结果',text:'中医诊断讲究“辨证论治“，证是中医学特有的诊断概念，是对疾病或身体状态当前阶段的病位、病性等所做的概括，是对疾病或身体状态当前本质所作的结论。证候不同于西医的疾病，更能反映疾病的本质 。证候会随着疾病的发展阶段和身体的健康状态变化而变化。'})"><van-icon name="question-o" />什么是中医体检结果</p>
                        <div class="hori-examin flex">
                            <div class="flex-1">
                                <div class="body-result flex">
                                    <div class="body-dialog flex-1">
                                        <div class="dialog-title">您的体检结果：</div>
                                        <div class="flex">
                                            <div v-for="(sick,index) in info.sick_text_array" v-bind:key="index" class="dialog">
                                                {{sick}}
                                            </div>
                                        </div>
                                    </div>
                                    <img src="../../../../images/robot.png" class="robot"/>
                                </div>
                                <div class="body-result flex" v-if="info.age>18 && !(info.sick_text_array.length==1&&(info.sick_text_array[0]=='平衡'||info.sick_text_array[0]=='基本平衡'||info.sick_text_array[0]=='健康'))">
                                    <div class="body-dialog bole flex-1">
                                        <div class="dialog-title">常见不适症状：</div>
                                        <div>{{info.viscera.syndrome_show}}</div>
                                    </div>
                                    <img src="../../../../images/robot.png" class="robot"/>
                                </div>
                            </div>
                            <div class="flex-1">
                                <div class="body-result flex">
                                    <div class="body-dialog bole flex-1">
                                        <div class="dialog-title">中医体检结果解读：</div>
                                        <div v-for="( item, index ) in info.sick_text_array" v-bind:key="index">
                                            <div>{{item}}</div>
                                            <div v-html="JSON.parse(info.viscera.syndrome_desc)[item]"></div>
                                        </div>
                                    </div>
                                    <img src="../../../../images/robot.png" class="robot"/>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="hori-box y3">
                        <div class="flex flex-x">
                            <div class="body-h1">体重指数(BMI)</div>
                        </div>
                        <p class="introduce" @click="alertFun({title:'什么是BMI',text:'BMI也称为身体质量指数（英文为Body Mass Index，简称BMI），是用体重公斤数除以身高米数平方得出的数字，是国际上常用的衡量人体胖瘦程度以及是否健康的一个标准。'})"><van-icon name="question-o" />BMI</p>
                        <div class="exponent flex">
                            <div class="duce_bmi_box flex-1">
                                <div class="duce">您的指数</div>
                                <div class="duce_bmi">{{ info.bmi }}</div>
                                <span class="weight-fat blue" v-if="MBItext(info.bmi)=='偏瘦'">{{ MBItext(info.bmi) }}</span>
                                <span class="weight-fat green" v-if="MBItext(info.bmi)=='正常'">{{ MBItext(info.bmi) }}</span>
                                <span class="weight-fat" v-if="MBItext(info.bmi)=='偏胖'">{{ MBItext(info.bmi) }}</span>
                                <span class="weight-fat orange" v-if="MBItext(info.bmi)=='肥胖'">{{ MBItext(info.bmi) }}</span>
                                <span class="weight-fat red" v-if="MBItext(info.bmi)=='重度肥胖'">{{ MBItext(info.bmi) }}</span>
                            </div>
                            <div class="flex-1">
                                <div class="duce">您的适合体重</div>
                                <div class="duce_bmi">{{ info.suit_weight }}<span>kg</span></div>
                            </div>
                        </div>
                        <div class="expons flex flex-v">
                            <div class="expon">
                                偏瘦:&lt;18.5
                            </div>
                            <div class="expon">
                                正常:18.5～23.9
                            </div>
                            <div class="expon">
                                偏胖:24～26.9
                            </div>
                            <div class="expon">
                                肥胖:27～29.9
                            </div>
                            <div class="expon">
                                重度肥胖:≥30
                            </div>
                        </div>
                    </div>
                    <div class="hori-box y4">
                        <div class="flex flex-x">
                            <div class="body-h1">身体七大系统评估</div>
                        </div>
                        <div class="assessment flex">
                            <div class="table-mark flex-1">
                                <table class="newre-table">
                                    <thead>
                                    <tr>
                                        <th>系统名称</th>
                                        <th>指数（5～1）</th>
                                    </tr>  
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item , index) in info.system" :key="index">
                                        <td>{{index}}</td>
                                        <td>{{item}}</td>
                                    </tr>
                                    </tbody>  
                                </table>
                            </div>
                            <div class="flex-1">
                                <div class="sick-dosc">
                                    <div>
                                        <p>"5"表示处于最佳状态</p>
                                        <p>"4"表示处于健康状态</p>
                                        <p>"3"表示处于轻度亚健康状态</p>
                                        <p>"2"表示处于重度亚健康状态</p>
                                        <p>"1"表示处于异常状态</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hori-box y5" >
                        <div class="flex flex-x" v-if="info.index_list">
                            <div class="hori-white ">
                                <div class="flex flex-x">
                                    <div class="body-h1">体重趋势图</div>
                                </div>
                                <div class="trend7">显示最近7天数据趋势</div>
                                <div class="flex">
                                    <!-- <div class="flex-1" v-if="info.index_list.score">
                                        <div class="vigour healthy-1 flex"></div>
                                        <div class="trend-alert">指数越高，身体健康越平衡</div>
                                        <div class="balance-meter broken" id="y-broken"></div>
                                    </div> -->
                                    <div v-if="info.index_list.bmi" class="flex-1">
                                        <div class="vigour healthy-1 flex">
                                            <div class="healthy">体重指数(BMI)</div>
                                        </div>
                                        <div class="trend-alert">体重指数（BMI）= 体重/身高 ^2</div>
                                        <div class="balance-meter broken" id="y-weight"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hori-box y6">
                        <div class="flex flex-x">
                            <div class="body-h1">我有哪些健康风险？</div>
                        </div>
                        <div class="hori-gpl">
                            <div class="body-gpl body-result flex">
                                <div class="body-dialog bole body-white flex-1">
                                    <div class="dialog-title">您需要关注的脏腑器官：</div>
                                    <div v-html="info.viscera.viscera"></div>
                                </div>
                                <img src="../../../../images/robot.png" class="robot"/>
                            </div>
                            <div class="body-gpl body-result flex" v-if="info.age>18 && !(info.sick_text_array.length==1&&(info.sick_text_array[0]=='平衡'||info.sick_text_array[0]=='基本平衡'||info.sick_text_array[0]=='健康'))">
                                <div class="body-dialog bole body-white flex-1">
                                    <div class="dialog-title">需要预防的重大疾病：</div>
                                    <div v-html="info.big.join('、')"></div>
                                </div>
                                <img src="../../../../images/robot.png" class="robot"/>
                            </div>
                            <div class="body-result flex" >
                                <div class="body-dialog bole body-white flex-1">
                                    <div class="dialog-title">您的健康风险：</div>
                                    <div v-if="info.age>18 && info.sick_text_array.length==1&&(info.sick_text_array[0]=='平衡'||info.sick_text_array[0]=='基本平衡'||info.sick_text_array[0]=='健康')">根据您的性别年龄和上述各项健康指标，结合临床大数据研究显示，相对于同年龄段的人群您患<span>{{info.big.join('、')}}</span>的概率更高。大部分疾病的发生和传变都是一个漫长的过程，根本原因在于身体健康状态的失衡。如果诸多轻症长久不调理或潜藏未觉，就会增加向重疾恶化的风险</div>
                                    <div v-else>良好</div>
                                </div>
                                <img src="../../../../images/robot.png" class="robot"/>
                            </div>
                        </div>
                    </div>
                    <div class="hori-box y7">
                        <div v-if="info.women">
                            <div class="flex flex-x">
                                <div class="body-h1">女性产后保健</div>
                            </div>
                            <div class="assessment assessment-no">
                                <div class="doscs-left">
                                    <div class="sick-doscs">
                                        <div class="sick-color">
                                            <div class="flex">
                                                <div class="sick-title flex flex-y flex-x">
                                                    <div>
                                                        <div>健</div>
                                                        <div>康</div>
                                                        <div>状</div>
                                                        <div>态</div>
                                                        <div>评</div>
                                                        <div>估</div>
                                                    </div>
                                                </div>
                                                <div class="sick-text flex-1 flex flex-y">
                                                    <div>
                                                        <div>{{info.women.first_part}}</div>
                                                        <div class="second">{{info.women.second_part}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="sick-color">
                                            <div class="flex">
                                                <div class="sick-title flex flex-y flex-x">
                                                    <div>
                                                        <div>产</div>
                                                        <div>褥</div>
                                                        <div>期</div>
                                                        <div>易</div>
                                                        <div>得</div>
                                                        <div>病</div>
                                                        <div>症</div>
                                                    </div>
                                                </div>
                                                <div class="sick-text flex-1 flex flex-y">
                                                    <div>根据您现在的中医健康状态分析，产褥期您需注意以下病症：{{info.women.stegmonth_disease}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="sick-color">
                                            <div class="flex">
                                                <div class="sick-title flex flex-y flex-x">
                                                    <div>
                                                        <div>产</div>
                                                        <div>后</div>
                                                        <div>恢</div>
                                                        <div>复</div>
                                                        <div>后</div>
                                                        <div>期</div>
                                                        <div>易</div>
                                                        <div>得</div>
                                                        <div>病</div>
                                                        <div>症</div>
                                                    </div>
                                                </div>
                                                <div class="sick-text flex-1 flex flex-y">
                                                    <div>根据您现在的中医健康状态分析，产后恢复期您需注意以下病症：{{info.women.stegmonth_disease}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 药茶养生、艾灸养生、经络养生、药膳养生、六茶养生、经方、音乐养生、起居指导 -->
                    <div class="hori-box" :class="`y${index+8}`" v-for="(items, index) in module.data" :key="index">
                        <div class="hori-width">
                        <div class="body-result flex" v-if="index==0">
                            <div class="body-dialog body-white flex-1">
                                <div class="dialog-title">以下是根据您的情况为您个性化定制的养生方案。</div>
                            </div>
                            <img src="../../../../images/robot.png" class="robot"/>
                        </div>
                        <div class="flex flex-x">
                            <div class="body-h1">{{items.module_name}}</div>
                        </div>
                        <div class="assessment assessment-no">
                            <div class="sick-list flex flex-x">
                                <div class="new-sick green-sick" v-if="info.sick_text_array.length==1&&(info.sick_text_array[0]=='平衡'||info.sick_text_array[0]=='基本平衡'||info.sick_text_array[0]=='健康')">平衡</div>
                                <div v-else :class="{active:item1.active}"  v-for="( item1, index1 ) in items.description_list" v-bind:key="index1" class="new-sick new-easy" @click="()=>change({
                                    index:index,
                                    index1:index1
                                })">
                                    {{item1.syndrome_name}}
                                </div>
                            </div>
                            <div class="sick-list flex">
                                <div v-for="( item2, index2 ) in items.description_list" v-bind:key="index2">
                                    <div v-if="item2.list && item2.active" class="flex flex-v">
                                        <div v-for="( item3, index3 ) in item2.list" v-bind:key="index3">
                                            <div class="new-sick new-green " :class="{active:item3.active}" v-if="item3.module_value[0].value" @click="()=>changeTwo({
                                                index:index,
                                                index1:index2,
                                                index2:index3
                                            })">{{item3.module_value[0].value}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-for="( item2, index3 ) in items.description_list" v-bind:key="index3">
                                <div v-if="item2.list && item2.active">
                                    <div  v-for="( item3, index3 ) in item2.list" v-bind:key="index3">
                                        <div v-if="item3.active">
                                            <div v-if="items.is_need_audio==1" class="sick-audio">
                                                <div v-for="( audio, index6 ) in item3.audio" v-bind:key="index6">
                                                    <audio :src="audio" controls="controls">
                                                        您的浏览器不支持html5的audio标签
                                                    </audio>
                                                </div>
                                            </div>
                                            <div class="sick-doscs">
                                                <div v-for="( item4, index4 ) in item3.module_value" v-bind:key="index4" class="sick-color">
                                                    <div v-if="index4>0" class="flex">
                                                        <div class="sick-title flex flex-y flex-x">
                                                            <div>
                                                                <div v-for="(title,title1) in item4.title" v-bind:key="title1">{{title}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="sick-text flex-1 flex flex-y" v-html="item4.value"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="items.is_need_image==1" class="sick-img">
                                                <div v-for="( img, index5 ) in item3.images" v-bind:key="index5">
                                                    <img :src="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="items.is_show_goods==1">
                                        <div v-for="( item3, index3 ) in item2.list" v-bind:key="index3">
                                            <div v-if="item3.active">
                                                <div class="syndrome-shop flex" v-if="item3.goodsURL" @click="()=>href(item3.goodsURL)">
                                                    <div class="syndrome-tea flex flex-1" >
                                                        <div class="syndrome-img" v-if="item3.goodsImg">
                                                            <img :src="item3.goodsImg"/>
                                                        </div>
                                                        <div class="tea-box flex-1" v-if="item3.goods_name && item3.goods_price">
                                                            <p>{{item3.goods_name}}</p>
                                                            <i><span>¥</span>{{item3.goods_price}}</i>
                                                        </div>
                                                    </div>
                                                    <div class="syndrome-look">前往查看</div>
                                                </div>
                                                <div v-if="items.is_show_goodthing==1 && item3.goods_more" class="syndrome-mose" @click="()=>href(item3.goods_more)">
                                                    更多好物
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="fen-page navclickToo">
                        <div class="s-page">
                            <div class="prev" @click="()=>pageFun({
                                page:page-1,
                                add:-1
                            })">
                                <div>上</div>
                                <div>一</div>
                                <div>页</div>
                            </div>
                        </div>
                        <div class="s-page">
                            <div class="next" @click="()=>pageFun({
                                page:page+1,
                                add:1
                            })">
                                <div>下</div>
                                <div>一</div>
                                <div>页</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="loading" class="load-ur flex flex-x flex-y">
                <van-loading size="60" text-color="#07B49A"/>
            </div>
            <div v-if="login">
                <vue-popup :showFun="loginFun" :callback="refrFun" :token="this.$route.params.id" />
            </div>
            <div v-if="history">
                <vue-popup :showFun="noLoginFun" :url="isLogin" :text="this.isLogin=='report'?'注册/登录':'搜索报告'" :login="true" :birthday="info.birthday" :token="this.$route.params.id" />
            </div>
            <div v-if="loginG">
                <vue-popup :showFun="noLoginG" url="" :login="true" :birthday="info.birthday" :token="this.$route.params.id" />
            </div>
            <div v-if="prescribe">
                <vue-popup :showFun="prescribeFun" :callback="presFun" :token="this.$route.params.id" :prescribe="true" :skipFun="skipFun" />
            </div>
            <div v-if="alert.text">
                <div class="loading black flex flex-x flex-y" @click="alertFun({title:'',text:''})">
                    <div class="loading-box" v-on:click.stop>
                        <div class="load-bac load-wx">
                            <div class="load-title">{{alert.title}}</div>
                            <div class="load-text">{{alert.text}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="loading black flex flex-x flex-y" v-if="send" @click="sendStateFun('send')">
                <div class="loading-box" v-on:click.stop>
                    <div class="load-bac load-wx">
                        <div class="flex">
                            <div class="flex-1"></div>
                            <van-icon name="cross" size="20"  @click="sendStateFun('send')"/>
                        </div>
                        <img src="../../../../images/icon.png"/>
                        <p>1、先识别手机上的二维码, 如果是公众号请关注。</p>
                        <p>2、再点击“发送报告”</p>
                        <div class="send-box" v-if="module_list.length>0">
                            <div class="send-moule">选择发送的模块</div>
                                <van-checkbox-group v-model="moduleName" direction="horizontal">
                                    <div class="send-checkbox" v-for="(items, index) in module_list" :key="index">
                                        <van-checkbox  shape="square" :name="items.id" checked-color="#07b49a">{{items.module_name}}</van-checkbox>
                                    </div>
                                </van-checkbox-group>
                        </div>
                        <div class="again" @click="sendFun" v-if="module_list.length==0 || module_name.length>0">发送报告</div>
                        <div class="again dim" v-else>发送报告</div>
                    </div>
                </div>
            </div>
            <div class="loading black flex flex-x flex-y" v-if="doctor" @click="sendStateFun('doctor')">
                <div class="overlay" v-on:click.stop>
                    <div v-if="erweim()">
                        <div class="overlay-whith" >
                            <img :src="erweim()"/>
                        </div>
                        <div class="overlay-text">医生扫码</div>
                        <div class="overlay-text size-min">医生用微信扫码为用户开方诊断</div>
                    </div>
                    <div v-else class="overlay-top">
                        <div class="overlay-text">请联系管理人员绑定公众号</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="print">
            <vue-print :user="user" :info="info" :MBItext="MBItext(info.bmi)" :module="module"  />
        </div>
    </div>
</template>
<script>
import vueHeader from '@/components/header';
import vuePrint from '@/views/report/print';
import { mapState, mapActions, mapMutations } from "vuex";
import { isInWechatMPO , makecode } from "@/utils";
import vuePopup from "@/components/popup";
import domain from "@/utils/config";
import { getDomain } from "@/utils";

export default {
    name: 'report',
    data(){
        return{
            bool:false,
            noToken:false,
            isLogin:'reportList',
            alert:{
                title:'',
                text:''
            }
        }
    },
    components:{
        vueHeader,
        vuePopup,
        vuePrint
    },
    created:function(){
        let returns = sessionStorage.getItem('return');
        if(!this.$route.query.return&&returns=='2'){
            this.noToken = true;
        }
        if(this.$route.query.token && !sessionStorage.token){
            sessionStorage.setItem('token',this.$route.query.token);
        }
        this.axiosData({
            report_token:this.$route.params.id
        })
        if(sessionStorage.token){
            this.axiosUser();
        }
    },
    destroyed: function () {
        this.closeFun();
    },
    computed: {
        moduleName:{
            get() { return this.$store.state.report.module_name },
            set(val){
                this.changeFun({
                    key:'module_name',
                    value:val
                });
            }
        },
        ...mapState ("report", ["info","module","loading","login","send","page","user","module_list","module_name","prescribe","doctor",'history','loginG']),
    },
    methods: {
        MBItext(bmi) {
            if (bmi <= 18.5) {
                return "偏瘦";
            } else if (bmi >= 18.5 && bmi<= 23.9) {
                return "正常";
            } else if (bmi >= 24 && bmi <= 26.9) {
                return "偏胖";
            } else if (bmi >= 27 && bmi <= 29.9) {
                return "肥胖";
            } else if (bmi >= 30) {
                return "重度肥胖";
            }
        },
        alertFun(text){
            this.alert = text;
        },

        //二维码
        erweim(){
            let equipment = JSON.parse(sessionStorage.getItem('equipment'));
            if(equipment){
                let url;
                if(this.bool){
                    url = `https://${equipment.appid}.device${domain}/addUser/${this.$route.params.id}`;
                }else{
                    url = `https://${equipment.appid}.device${domain}/prescribeQuestion/${this.$route.params.id}`;
                }
                return makecode(url);
            }else{
                return false;
            }
        },

        // //返回
        // returnFun(){
        //     if(this.$route.query.return==2){
        //         this.$router.back('-1');
        //     }else{
        //         this.$router.push({path: `/reportList/${this.$store.state.report.info.user_id}`});
        //     }
        // },
        
        href(item){
            // 小程序 webview 环境
            if (isInWechatMPO()) {
                let url = this.GetRequest(item);
                let wx = window.wx;
                wx.miniProgram.navigateTo({ url: `/pages/navigator/index?path=${url.path}&id=${url.id}&appid=${url.appid}` });
                return;
            }else{
                window.location.href = item;
            }
        },

        isLoginFun(bool){
            this.isLogin = bool?'report':'reportList';
        },

        //小程序分割数据
        GetRequest(url){
            var theRequest = {};
            if (url.indexOf("?") != -1) {
                var str = url.split("?");
                theRequest.path = str[0];
                var strs = str[1].split("&");
                for(var i = 0; i < strs.length; i ++) {
                    theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
                }
            }
            return theRequest;
        },
        //登录
        loginGFun(){
            let device = sessionStorage.getItem('device');
            if(device=='android' || device=='windows'){
                this.changeFun({
                    key:'loginG',
                    value:true
                })
            }else{
                let appid = getDomain();
                sessionStorage.setItem("isToken",true);
                window.location.href =  `http://${appid}${domain}?Callback=${encodeURI(window.location.href)}&source=3`;
            }
        },
        noLoginG(){
            this.changeFun({
                key:'loginG',
                value:false
            })
        },
        //登录完成
        loginFun(){
            this.changeFun({
                key:'login',
                value:false
            })
        },
        noLoginFun(){
            this.changeFun({
                key:'history',
                value:false
            })
        },
        //开方
        prescribeFun(){
            this.changeFun({
                key:'prescribe',
                value:false
            })
        },
        //发送报告登录完成的后续工作
        refrFun(){
            this.bool = false;
            this.axiosData({
                report_token:this.$route.params.id
            });
            this.axiosUser();
            this.changeFun({key:'login',value:false});
            this.changeFun({key:'send',value:true});
            let equipment = JSON.parse(sessionStorage.getItem('equipment'));
            if(equipment && equipment.send_template_select==1){
                this.sendMain({
                    equipment_code:equipment.equipment_code
                });
            }
        },
        //历史记录登录完成后的后续公众
        historyFun(){
            this.$router.push({
                name:'reportList'
            });
        },
        //登录完成后开方后续工作
        presFun(){
            this.changeFun({key:'prescribe',value:false});
            this.changeFun({key:'doctor',value:true});
        },
        //跳过
        skipFun(bool){
            this.changeFun({key:'prescribe',value:false});
            this.changeFun({key:'doctor',value:true});
            this.bool = bool;
        },
        //关闭模版
        sendStateFun(key){
            this.changeFun({
                key:key,
                value:false
            })
        },
        //发送模版消息
        sendFun(){
          if(sessionStorage.token){
            //发送模版消息
            this.axiosSend({
                data:{
                    token:this.$route.params.id
                }
            })
          }
        },
        //翻页
        pageFun(val){
            this.pageChange(val);
        },
        ...mapMutations ("report", ["change","changeTwo","changeFun","pageChange","closeFun"]),
        ...mapActions ("report", ["axiosData","axiosSend","axiosUser","sendMain"])
    }
}
</script>
<style lang="scss" scoped>
  @import './report';
</style>